.treelabels {
  border: 1px solid #d9d9d9;
  max-height: 350px;
  overflow-y: auto;
}

.item {
  color: get-color(error);
  font-size: 14px;

  &:hover {
    cursor: pointer;
    color: #f5222d;
  }
}

.lstitem {
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  padding: 6px 0 6px 6px;

  & > *:not(:last-child),
  & [role="img"] {
    margin-right: 6px;
  }

  &:hover {
    background: #f5f5f5;

    .node {
      color: #1890ff;
    }
  }

  &.flat {
    margin-left: -16px;
  }

  &.hidden {
    opacity: 0.3;
  }

  & button.hidden,
  & button.visible {
    height: 20px;
    display: flex;
    align-items: flex-end;
    opacity: 0.8;
    span {
      margin-right: 0;
    }
  }

  &.selected {
    background: #bae7ff;
  }
}

.list .lstitem {
  border-bottom: none;
}

.ant-tree-switcher {
  margin-top: 15px;
}
